/* General App Styles */
.App {
  text-align: center;
}

.hero-image {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  object-position: center 70%;
  margin-bottom: 20px;
  border-bottom: 5px solid #ff0000; /* Autobots red border */
}

/* Old Site Button */
.old-site-button {
  display: inline-block;
  margin: 20px auto; /* Center the button horizontally */
  background-color: #0033cc; /* Autobots blue */
  color: #ffffff; /* White text */
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.old-site-button:hover {
  background-color: #002299; /* Darker blue on hover */
}

/* Header Styles */
.App-header {
  background-color: #ff0000; /* Autobots red */
  padding: 20px;
  color: #ffffff; /* White text */
  text-align: center; /* Ensure all header content is centered */
}

/* Item Styles */
.item {
  margin: 20px;
  padding: 20px;
  border: 1px solid #c0c0c0; /* Silver border */
}

/* Mosaic Styles */
.mosaic {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Maximum of 3 images per row */
  gap: 10px;
  margin-top: 10px;
  justify-content: center; /* Center the grid container */
}

.mosaic img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Center single image */
.mosaic > :only-child {
  grid-column: span 3; /* Span all 3 columns */
}

/* Center two images */
.mosaic > :nth-child(2):nth-last-child(2) {
  grid-column: span 2; /* Span 2 columns for each image */
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  width: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Prevent the image from exceeding the modal's width */
  max-height: 90vh; /* Prevent the image from exceeding 90% of the viewport height */
  border-radius: 8px;
  object-fit: contain; /* Ensure the image fits within the modal */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

/* Footer Styles */
footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #0033cc; /* Autobots blue */
  color: #ffffff; /* White text */
}

/* Content Layout */
.content {
  display: flex;
}

/* Left Rail Styles */
.left-rail {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #f4f4f4;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transform: translateX(-100%); /* Hidden by default */
  transition: transform 0.3s ease; /* Smooth slide-in/out */
  z-index: 1000;
}

.left-rail ul {
  list-style: none;
  padding: 0;
}

.left-rail li {
  margin-bottom: 10px;
}

.left-rail a {
  text-decoration: none;
  color: #007bff;
}

.left-rail a:hover {
  text-decoration: underline;
}

.left-rail.visible {
  transform: translateX(0); /* Slide in */
}

.left-rail.hidden {
  transform: translateX(-100%); /* Slide out */
}

/* Floating Buttons */
.floating-buttons {
  position: fixed;
  bottom: 20px;
  left: 20px; /* Move to the left side */
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

/* Scroll-to-Top Button */
.scroll-to-top {
  background-color: #0033cc;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scroll-to-top:hover {
  background-color: #002299;
}

/* Bottom "Hide Menu" Button */
.toggle-left-rail.bottom {
  position: fixed;
  bottom: 20px;
  left: 20px; /* Align with the floating buttons */
  z-index: 1000;
}

/* Toggle Left Rail Button */
.toggle-left-rail {
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.toggle-left-rail:hover {
  background-color: #cc0000;
}

/* Main Content */
main {
  flex: 1;
  padding: 20px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .left-rail {
    transform: translateX(-100%); /* Start hidden on mobile */
  }
}

.youtube-container {
  margin-top: 10px;
}

.youtube-container a {
  display: inline-block;
  background-color: #ff0000; /* YouTube red */
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.youtube-container a:hover {
  background-color: #cc0000; /* Darker red on hover */
}
